@import '../../variable.scss';

.editor {
  background-color: $green;
  padding: 20px 10px;
  border: 1px solid $black;
  border-radius: 10px;
}

.account {
    &__label-file {
        display: inline-block;
        background-color: $gray;
        border-radius: 10px;
        border: 1px solid $light-gray;
        text-transform: uppercase;
        text-decoration: none;
        color: $white;
        font-size: 16px !important;
        padding: 7px 0;
        width: 35%;
        text-align: center;
        cursor: pointer;
    
        &:hover {
          background-color: darken($gray, 10%);
        }
    }
    
    &__image {
      display: none;
    }
    
    &__count-image {
      @include simpleText();
      margin-left: 20px;
    }

  &__product {
    &-img {
      width: 100%;
      height: auto;
      border-radius: 10px;
      position: relative; // Додано для позиціонування кнопки видалення

        &-delete {
            position: absolute;
            top: 10px;
            right: 10px; // Позиціонування кнопки у правому верхньому куті
            border: none;
            outline: none;
            border-radius: 10px;
            width: 30px;
            height: 30px;
            background-color: #D92323;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
             z-index: 1; // Забезпечує, щоб кнопка була поверх зображення
    
            &:hover {
              background-color: darken(#D92323, 10%);
            }
    
            svg {
                width: 18px;
                height: 18px;
            }
          }
    }

      &-create {
          @include button();
          width: 32%;
          margin-top: 50px;
      }

      &-save,
      &-delete {
          @include button();
          width: 45%;
      }

      &-delete {
          background-color: #D92323;

          &:hover {
              background-color: darken(#D92323, 10%);
          }
      }
  }

  &__slider-photo {
      width: calc(100% - 180px);
      position: relative;
      margin: 50px auto;
      
      .swiper-slide {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
  }


  .size-buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 50px;
    }

    .size-button {
      background-color: #f0f0f0;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 8px 12px;
      cursor: pointer;
  
      &.selected {
        background-color: #007bff;
        color: white;
        border-color: #007bff;
      }
    }
}