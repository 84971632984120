@import  '../../variable.scss';

.account{
  
    &__user{
        margin-top: 50px;
    }

    &__phone {
        padding-left: 50px !important;
    
        & ~ label{
            top: -20px !important;
            font-size: 14px !important;
            line-height: 22px !important;
        }
    }

    &__old-pass{
        width: 45%;
    }

    &__phone-country{
        position: absolute;
        top: 10px;
        left: 5px;
    }

    &__subtitle{
        color: $white;
        @include text(24px, 500, 35px);
        margin: 50px 0 0;
    }

    &__save{
        @include button();
        width: 32%;
        margin-top: 50px;
    }
}