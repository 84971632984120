.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Трохи більше контрасту */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: opacity 0.5s ease-in-out;
}

.modal-content {
    background-color: #ffffff;
    padding: 35px;
    border-radius: 15px; /* Округліші кути */
    max-width: 780px; /* Трохи ширше модальне вікно */
    width: 85%;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3); /* Глибша тінь */
    opacity: 0;
    transform: translateY(-60px) scale(0.95);
    animation: popIn 0.6s ease forwards; /* Анімація для появи */
}

/* Нова анімація появи з ефектом спливаючого вікна */
@keyframes popIn {
    0% {
        opacity: 0;
        transform: translateY(-60px) scale(0.95);
    }
    100% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

.ordered_form_title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 30px;
    text-align: center;
    color: #444;
    letter-spacing: 1.2px; /* Легкий трюк з текстом для сучаснішого вигляду */
    opacity: 0;
    animation: fadeIn 0.8s ease forwards;
    animation-delay: 0.3s;
}

.flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 30px;
    opacity: 0;
    animation: fadeIn 1s ease forwards;
    animation-delay: 0.5s;
}

.cart_in {
    max-width: 150px; /* Більший розмір зображення */
    max-height: 150px;
    width: auto;
    height: auto;
    object-fit: cover;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Додаємо тінь для зображення */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Анімація при наведенні */
}

.cart_in:hover {
    transform: scale(1.05); /* Збільшуємо зображення при наведенні */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Глибша тінь при наведенні */
}

.tlc {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
    color: #555;
}

.prac {
    font-size: 20px; /* Трохи більший розмір */
    color: #d32f2f;
    font-weight: 700;
}

.alc {
    align-items: center;
}

.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    opacity: 0;
    animation: fadeIn 1.2s ease forwards;
    animation-delay: 0.7s;
}

.btn {
    padding: 15px 20px;
    border: none;
    border-radius: 8px; /* Округліші кнопки */
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    width: 48%;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    margin-bottom: 10px;
}

/* Анімація при наведенні на кнопки */
.btn:hover {
    transform: translateY(-5px); /* Легке піднімання */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Додаємо тінь при наведенні */
}

/* Легка пульсація при наведенні */
.btn:active {
    transform: translateY(0); /* Ефект "натискання" */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.btn-default {
    background-color: #ff7043;
    color: #fff;
}

.btn-default:hover {
    background-color: #ff5722; /* Насичений помаранчевий */
}

.gogo {
    background-color: #e0f7fa;
    color: #00796b;
}

.gogo:hover {
    background-color: #b2ebf2;
}

/* Анімація для плавної появи */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Анімація для зникнення */
@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0.9);
    }
}
