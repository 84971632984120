.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    text-align: center;
  }
  
  .modal-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .modal-message {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .btn {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .btn.gogo {
    background-color: #4CAF50;
    color: white;
  }
  
  .btn.gogo:hover {
    background-color: #45a049;
  }
  