@import './variable.scss';

*, *::after, *::before{
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}

body{
    background-color: #DAF0EE;
}

.container{
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 20px;
}

.title2{
    @include title2();
}

.header{
    position: relative;
    background: linear-gradient(90deg, rgba(59,65,60,1) 50%, rgba(148,209,190,1) 50%);

    &__wrapper{
        height: calc(100vh - 75px);
        color: $white;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__content{
        width: 40%;
    }

    &__title{
        @include text(28px, 800, 48px);
        margin: 0;
    }

    &__text{
        @include text(28px, 400, 48px);
        margin: 50px 0;
    }

    &__img{
        position: absolute;
        width: 45%;
        right: 3%;
        height: auto;
    }

    &__shop{
        @include button();
    }
}
.sale, .recommend, .account{
    .swiper{
        position: static;

        &-wrapper{
            display: flex;
            align-items: stretch;
        }

        &-slide{
            background-color: #fff;
            display: block;
            border-radius: 10px;
            height: auto !important;
            Link{
                display:flex !important;
            }
        
            .product{
                height: 100%;
                &__img{
                    width: 100%;
                }
            }
        }

        &-button{ 
            &-prev, &-next{
                width: 54px;
                height: 54px;
                background: $green;
                position: absolute;
                border-radius: 100%;
                &::after{
                    content: url('data:image/svg+xml; utf8, <svg width="16" height="30" viewBox="0 0 16 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.84961 28.1998L12.7163 17.3331C13.9996 16.0498 13.9996 13.9498 12.7163 12.6665L1.84961 1.7998" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                    position: relative;
                }
            }

            &-prev{
                left: -90px;
                &::after{
                    transform: rotate(180deg);
                    left: -2px;
                    top: 2px;
                }
            }

            &-next{
                right: -90px;
                &::after{
                    right: -2px;
                }
            }
        }

        &-scrollbar{
            bottom: -40px !important;
            background-color: $light-green;
            cursor: pointer;

            &-drag{
                background-color: $green;
            }
        }
    }
}

.account{
    .swiper{
        &-slide{
            background-color: transparent;
            display: block;
            border-radius: none;
            text-align: center;
        }

        &-button{ 
            &-prev, &-next{
                width: 40px;
                height: 40px;
                background: $gray;
                position: absolute;
                border-radius: 100%;
                &::after{
                    content: url('data:image/svg+xml; utf8, <svg width="16" height="20" viewBox="0 0 16 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.84961 28.1998L12.7163 17.3331C13.9996 16.0498 13.9996 13.9498 12.7163 12.6665L1.84961 1.7998" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                    position: relative;
                }
            }

            &-prev{
                left: -90px;
                &::after{
                    transform: rotate(180deg) ;
                     left: -2px;
                     top: 6px;
                }
            }

            &-next{
                right: -90px;
                &::after{
                     right: -2px;
                     top: -6px;
                }
            }
        }
    }

    
}

.error{
    color: #D92323;
    margin: 5px 0 0 5px;
    text-align: left;
    @include text(14px, 400, 25px);
}


@media screen and (max-width: 991px){
    .header{
        &__text{
            @include text(24px, 400, 40px);
        }

        &__shop{
            width: 75%;
        }
    }
}

@media screen and (max-width: 760px){
    .header{
        position: relative;
        background: linear-gradient(rgba(59,65,60,1) 50%, rgba(148,209,190,1) 50%);

        &__wrapper{
            height: auto;
            color: $white;
            display: block;
            padding-bottom: 30px;
        }

        &__content{
            padding: 100px 0 20px 0;
            width: 100%;
            text-align: center;
        }

        &__img{
            position: static;
            width: 70%;
            height: auto;
            margin: 50px auto;
            display: block;
        }

        &__text{
            @include text(22px, 400, 40px);
            margin: 25px 0;
        }

        &__shop{
            width: 60%;
        }
    }

    .sale, .recommend, .account{
        .swiper{
            &-button{ 
                &-prev, &-next{
                    width: 40px;
                    height: 40px;
                    left: calc(-50% + 10px); // або інше значення
        right: calc(-50% + 10px);
                }

                &-prev{
                    left: -90px;
                    &::after{
                        transform: rotate(180deg);
                        left: -2px;
                        top: 2px;
                    }
                }

                &-next{
                    right: -90px;
                    &::after{
                        right: -2px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 450px){
    .sale, .reccomend{
        .swiper{
            &-button{
                &-prev, &-next{
                    display: none;
                }
            }
        }
    }
}