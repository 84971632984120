@import  '../../variable.scss';

.auth{
    .login, .registration{
        padding: 0 75px;
        width: 50%;
        text-align: center;
        position: relative;

        &__title{
            font-weight: 400;
        }

        &__error{
            text-align: center;
            position: absolute;
            display: block;
            margin: 0;
            left: 50%;
            top: -50px;
            transform: translateX(-50%)
        }

        &__open{
            @include button();
            display: block;
            margin: 0 auto;
            margin-top: 50px;
            width: 75%;
        }

        &__next{
            @include button();
            margin-top: 50px;
            width: 75%;
        }
        
        &__form{
            margin-top: 50px;
        }

        &__item {
            margin-top: 50px;
            position: relative;

            label{
                position: absolute;
                left: 5px;
                top: 3px;

                @include text(18px, 400, 33px);
            }

            &-flex{
                display: flex;
                justify-content: space-between;

                div{
                    position: relative;
                    width: 45%;
                }
            }
        }
        
        &__email, &__password, &__name, &__lastname{
            width: 100%;
            background-color: transparent;
            border: none;
            outline: none;
            border-bottom: 1px solid #fff;
            padding: 10px 5px;
            color: $white;

            &:focus ~ label, &:not(:placeholder-shown) ~ label{
                top: -20px;
                @include text(14px, 400, 22px);
            }

        }
    }

    .login__form, .registration__form{
        display: none;
    }

    &__check{
        display: none;

        &:not(:checked) ~ .login .login__form, &:checked ~.registration .registration__form{
            display: block;
        } 

        &:not(:checked) ~ .login .login__open, &:checked ~ .registration .registration__open{
            display: none;
        }
    }

    .registration{
        color: $gray;
        &__email, &__password, &__name, &__lastname{
            border-color: $gray;
            color: $black;
        }
    }
}

@media screen and (max-width: 900px){
    .auth{
        .login, .registration{
            padding: 0 20px;

            &__next, &__open{
                font-size: 18px;
            }

            &__open{
                margin-top: 20px;
            }

            &__item{
                margin-top: 30px;

                label{
                    font-size: 16px;
                }
            }

            &__item-flex{
                display: block;
                
                div{
                    width: 100%;

                    & + div {
                        margin-top: 30px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 760px){
    .header{
        background: rgb(148, 209, 190);
    }

    .auth{
        .login, .registration{
            width: 75%;
            margin: 0 auto;
            padding: 50px 0;

            &__next, &__open{
                font-size: 16px;
                width: 50%;
            }
        }
    }
}

@media screen and (max-width: 520px){
    .auth{
        .login, .registration{
            &__next, &__open{
                width: 75%;
            }
        }
    }
}

@media screen and (max-width: 420px){
    .auth{
        .login, .registration{
            width: 90%;
        }
    }
}