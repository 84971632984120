@import '../../variable.scss';

.product{
    &__img-small{
        width: 160px;
        height: auto;
        border-radius: 10px;
    }

    &__name{
        text-decoration: none;
        color: $black;
    }

    &__info{
        margin-left: 30px;
    }

    &__brand, &__size, &__count{
        @include simpleText();
        margin-top: 5px;
    }

    &__price{
        @include text(24px, 400, 33px);
        margin: 0;
        margin-top: 10px;
        span{
            font-weight: 500;
        }
    }
}


.count{
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.minus, .add, .counter{
    padding: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    background-color: $gray;
    color: $white;
    border: none;

    @include simpleText();
    text-align: center;
    outline: none;
}

.minus{
    border-radius: 10px 0 0 10px;
    cursor: pointer;

    svg{
        transform: translateY(-6px);
    }
} 
.add{
    border-radius: 0 10px 10px 0;
    cursor: pointer;
}

@media screen and (max-width: 768px){
    .product{
        &__img-small{
            width: 130px;
        }
        &__name, &__price{
            font-size: 20px;
        }

        &__brand, &__size, &__count{
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 678px){
    .product{
        &__info{
            margin-left: 10px;
        }
    }
}
