@import '../../variable.scss';

.favourite{
    padding: 100px 0;
    background-color: $green;

    &__products{
        margin-top: 50px;
    }

    &__product{
        display: flex;
        padding-bottom: 30px;
        border-bottom: 1px solid $white;
        position: relative;
        
        & + &{
            margin-top: 50px;
        }
    }

    .product__img{
        width: 150px;
    }
    
    &__delete{
        cursor: pointer;
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;

        position: absolute;
        top: 0;
        right: 0;
        svg{
            width: 25px;
            height: 25px;
            path{
                fill: lighten($red, 30%);
            }
        }

        &:hover{
            svg{
                path{
                    fill: lighten($red, 10%);
                }
            }
        }
    }

    &__empty{
        height: 50vh;
        position: relative;
        div{
            position: absolute;
            top: 30%;
            left: 0;
            text-align: center; 
            width: 100% 
        }
        
        
        &-text{
            @include text(32px, 500, 35px);
            color: $white;
           
        }
    }

    &__shop{
        @include button();
        width: 20%;
    }
}

@media screen and (max-width: 900px){
    .favourite{
        &__shop{
            width: 40%;
        }
    }
}

@media screen and (max-width: 768px){
    .favourite{
        padding: 50px 0;
        .product__img{
            width: 120px;
        }

        &__empty-text{
            font-size: 26px;
        }

        &__shop{
            font-size: 18px;
        }
    }
}

@media screen and (max-width: 500px){
    .favourite{
        &__shop{
            width: 80%;
        }
    }
}