.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 0;
  list-style: none;
  align-items: center;
  
  &__page, &__prev, &__next, &__break {
      padding: 8px 12px;
      margin: 0 3px;
      cursor: pointer;
      border-radius: 4px;
      text-decoration: none;
      color: #212529;
      transition: all 0.2s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
  
  
      &:hover {
          background-color: #f0f0f0;
      }
  }
  
  &__page--active {
      background-color: #d1e4f0;
      color: black;
      font-weight: bold;
  }
  
  &__prev, &__next {
      &:disabled {
          color: gray;
          cursor: default;
          opacity: 0.5;
          &:hover{
              background-color: transparent;
          }
      }
      
      &--hidden{
        display: none;
      }
  }

}