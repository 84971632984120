@import "../../variable.scss";

.RichEditor{
    &-root {
        background: transparent;
        border: 1px solid $black;
        border-radius: 10px;
        padding: 15px;
    }

    &-editor {
        border-top: 1px solid $black;
        cursor: text;
        font-size: 16px;
        margin-top: 10px;
    }

    &-editor .public-DraftEditorPlaceholder-root,
    &-editor .public-DraftEditor-content {
        margin: 0 -15px -15px;
        padding: 15px;
    }

    &-editor .public-DraftEditorPlaceholder-root{
        color: lighten($color: $black, $amount: 30%);
    }

    &-editor .public-DraftEditor-content {
        min-height: 100px;
    }

    &-editor &-blockquote {
        border-left: 5px solid #eee;
        color: #666;
        font-family: 'Hoefler Text', 'Georgia', serif;
        font-style: italic;
        margin: 16px 0;
        padding: 10px 20px;
    }

    &-styleButton {
        color: $black;
        cursor: pointer;
        margin-right: 16px;
        padding: 2px 0;
        display: inline-block;
    }
    &-activeButton {
        color: #5890ff;
    }
}

  