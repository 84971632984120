@import '../../variable.scss';

.popular{
    padding: 100px 0;

    &__products{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px 20px;
        margin-top: 50px;
    }

    .product{
        width: 18%;
    }
}

.sale{
    background-color: $gray;
    padding: 100px 0 140px 0;

    &__title{
        color: $white;
    }

    &__wrapper{
        position: relative;
        width: calc(100% - 180px);
        margin: 50px auto 0 auto;
    }
}

.location{
    padding: 100px 0;

    &__wrapper{
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__content{
        width: 45%;
    }

    &__subtitle{
        @include text(28px, 400, 42px);
        margin: 0;
    }

    &__address, &__text{
        @include simpleText();
        margin-top: 20px;
    }

    .schedule{
        list-style: none;
        margin-top: 0;
        &__item{
            @include text(18px, 400, 28px);
            margin-bottom: 10px;
        }
    }
}

.mapouter{
    position:relative;
    text-align:right;
    width:50%;
    height:400px;
    border-radius: 10px;
}
.gmap_canvas {
    overflow:hidden;
    background:none;
    width:100%;
    height:400px;
}
.gmap_iframe {
    height:400px;
    border-radius: 10px;
}

@media screen and (max-width: 1200px){
    .popular{
        .product{
            width: 23%;
        }
    } 
}

@media screen and (max-width: 991px){
    .popular{
        .product{
            width: 30%;
        }
    } 
}

@media screen and (max-width: 676px){
    .popular{
        .product{
            width: 40%;
        }
    } 

    .location{
        &__wrapper{
            display: block;
        }
        &__content{
            width: 100%;
        }
    }

    .mapouter{
        width:100%;
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 550px){
    .popular{
        .product{
            width: 45%;
        }
    } 
}
@media screen and (max-width: 450px){
    .popular{
        .product{
            width: 75%;
        }
    }
    
    .sale{
        &__wrapper{
            width: 100%;
        }
    }
}

