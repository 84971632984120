@import '../../variable.scss';

.goods {
  padding: 100px 0;

  &__all {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px 20px;
    margin-top: 50px;
  }

  .product {
    width: 18%;
  }
}

.filter {
  background-color: $green;
  padding: 15px 10px;
  border-radius: 10px;

  &__top {
    display: flex;
    justify-content: space-between;

    select {
      background-image: url('data:image/svg+xml;charset=US-ASCII,<svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.71875 0.947937L6.5 5.71877L11.2812 0.947937L12.75 2.41669L6.5 8.66669L0.25 2.41669L1.71875 0.947937Z" fill="black"/></svg>');
      background-repeat: no-repeat;
      background-position: right 1rem center;
      background-size: 10px 12px;
      width: 17%;
      padding: 5px;
      border: none;
      outline: none;
      border-radius: 10px;
      cursor: pointer;
      -webkit-appearance: none;
      appearance: none;
      @include text(16px, 400, 33px);
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
  }

  &__apply,
  &__delete {
    @include button();
    width: 20%;
    padding: 10px 0;
  }

  &__delete {
    background-color: $red;

    &:hover {
      background-color: darken($red, 5%);
    }
  }

  &__search {
    width: 30%;
    display: flex;
    position: relative;

    &-input {
      width: 100%;
      padding: 10px 40px 10px 10px;
      border: none;
      outline: none;
      border-radius: 10px;
    }

    &-btn {
      position: absolute;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      background-image: url('data:image/svg+xml;charset=US-ASCII, <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.4999 11H11.7099L11.4299 10.73C12.6299 9.33001 13.2499 7.42002 12.9099 5.39002C12.4399 2.61002 10.1199 0.390015 7.31989 0.0500152C3.08989 -0.469985 -0.470107 3.09001 0.0498932 7.32001C0.389893 10.12 2.60989 12.44 5.38989 12.91C7.41989 13.25 9.32989 12.63 10.7299 11.43L10.9999 11.71V12.5L15.2499 16.75C15.6599 17.16 16.3299 17.16 16.7399 16.75C17.1499 16.34 17.1499 15.67 16.7399 15.26L12.4999 11ZM6.49989 11C4.00989 11 1.99989 8.99002 1.99989 6.50002C1.99989 4.01002 4.00989 2.00002 6.49989 2.00002C8.98989 2.00002 10.9999 4.01002 10.9999 6.50002C10.9999 8.99002 8.98989 11 6.49989 11Z" fill="black"/></svg>');
      background-repeat: no-repeat;
      background-position: center;
      z-index: 5;
      width: 24px;
      height: 24px;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .goods {
    .product {
      width: 23%;
    }
  }
}

@media screen and (max-width: 991px) {
  .filter {
    &__top {
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px 20px;

      select {
        width: 30%;
      }
    }

    &__bottom {
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px 20px;
    }

    &__apply,
    &__delete {
      width: 40%;
      padding: 5px 10px;
    }

    &__search {
      width: 50%;
      margin-top: 20px;
    }
  }

  .goods {
    .product {
      width: 30%;
    }
  }
}

@media screen and (max-width: 676px) {
  .goods {
    .product {
      width: 40%;
    }
  }
}

@media screen and (max-width: 550px) {
  .filter {
    &__top {
      select {
        width: 40%;
      }
    }

    &__search {
      width: 80%;
    }
  }

  .goods {
    .product {
      width: 45%;
    }
  }
}

@media screen and (max-width: 450px) {
  .filter {
    &__top {
      select {
        width: 45%;
      }
    }

    &__sort {
      width: 95% !important;
    }

    &__bottom {
      display: block;
      text-align: center;
    }

    &__apply {
      width: 75%;
    }

    &__delete {
      display: block;
      margin: 20px auto 0;
    }

    &__search {
      width: 100%;
    }
  }

  .goods {
    .product {
      width: 90%;
    }
  }
}

/*  Стилі для слайдера  */
.cart__photos {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .cart__main-photo {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .cart__thumbs {
    width: 100%;
    overflow: hidden;
    margin-top: 10px;
  }
  
  .cart__img-thumb {
    width: 100%;
    height: auto;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .swiper-slide {
    width: auto;
    display: flex;
    align-items: center;
  }