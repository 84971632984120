@import "../../variable.scss";


.guarantee{
    padding: 100px 0;
    background-color: $green;
    &__text, &__item{
        @include simpleText();
        margin-bottom: 20px;
    }

    &__content{
        margin-top: 50px;
    }

    &__subtitle{
        @include text(28px, 400, 35px);
        margin-top: 50px !important;
    }

    &__date, &__contact{
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        margin: 30px 0;

        div{
            width: 32%;
            padding: 15px;
            background-color: $gray;
            color: $white;
            border-radius: 10px;
        }
    }

    &__period, &__social{
        @include text(28px, 400, 35px);
        margin: 0;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 767px){
    .guarantee{
        &__date, &__contact{
            display: block;
            div{
                width: 100%;                
            }

            div+div{
                margin-top: 20px;
            }
        }
    }
}