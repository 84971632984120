@import '../../variable.scss';

.basket{
    padding: 100px 0;
    background-color: $green;

    &__wrapper{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 50px;
    }

    &__products{
        width: 60%;
    }

    &__product{
        display: flex;
        align-items: center;
        padding-bottom: 30px;
        border-bottom: 1px solid $white;
        position: relative;
        
        & + &{
            margin-top: 50px;
        }
    }

    &__sum{
        width: 30%;
        padding: 20px;
        background-color: $light-green;
        border-radius: 10px;
    }

    &__cost, &__payable{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include simpleText();
        margin-bottom: 15px;
        p{
            margin: 0;
        }
    } 

    &__payable p:first-child{
        @include text(24px, 700, 40px);
    }

    &__pay{
        @include button();
        width: 100%;
        margin-top: 30px;
    }

    &__delete {
        background-color: transparent;
        border: none;
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 10px;
        right: 10px;
        transition: background-color 0.3s ease;
    
        svg {
            width: 20px;
            height: 20px;
            fill: #000000; // Початковий колір іконки
            transition: fill 0.3s ease; // Плавний перехід кольору
        }
    
        &:hover {
            background-color: rgba(255, 0, 0, 0.1);
            svg {
                fill: #ff0000; // Червоний колір іконки при наведенні
            }
        }
    
        &:active {
            background-color: rgba(255, 0, 0, 0.2);
        }
    }
    

    &__empty{
        height: 50vh;
        position: relative;
        div{
            position: absolute;
            top: 30%;
            left: 0;
            text-align: center; 
            width: 100% 
        }
        
        
        &-text{
            @include text(32px, 500, 35px);
            color: $white;
           
        }
    }

    &__shop{
        @include button();
        width: 20%;
    }

}

@media screen and (max-width: 1000px){
    .basket{
        &__sum{
            width: 35%;
        }
    }
}

@media screen and (max-width: 900px){
    .basket{
        &__shop{
            width: 40%;
        }
    }
}

@media screen and (max-width: 850px){
    .basket{
        .basket__wrapper{
            display: block;
        }
        &__products{
            width: 100%;
        }
        &__sum{
            margin: 40px auto 0;
            width: 50%;
        }
    }
}
@media screen and (max-width: 768px){
    .basket{
        padding: 50px 0;

        &__empty-text{
            font-size: 26px;
        }

        &__shop{
            font-size: 18px;
        }
    }
}

@media screen and (max-width: 600px){
    .basket{
        padding: 50px 0;
        
        &__sum{
            width: 75%;
        }
    }
}

@media screen and (max-width: 500px){
    .basket{
        &__shop{
            width: 80%;
        }
    }
}

@media screen and (max-width: 400px){
    .basket{
        &__sum{
            width: 100%;
        }
    }
}




.basket__form {
    display: flex;
    flex-direction: column;
    gap: 10px; // Відстань між полями

    input, textarea {
        width: 100%; // Встановлюємо ширину в 100%
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 14px;
        transition: border-color 0.3s;

        &:focus {
            border-color: #007bff; // Колір рамки при фокусі
            outline: none; // Прибираємо обведення
        }
    }

    textarea {
        resize: none; // Забороняємо змінювати розміри
        height: 80px; // Висота текстового поля
    }
}


.basket__pay {
    margin-top: 20px; // Відстань зверху
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0056b3; // Темніший колір при наведенні
    }
}

