@import '../../variable.scss';

.product {
    background-color: #fff;
    padding: 15px;
    border-radius: 15px;
    text-align: center;
    position: relative;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;

    &.sale {
        border: 2px solid #D53F00;
        box-shadow: 0 6px 20px rgba(213, 63, 0, 0.3);

        &:hover {
            transform: translateY(-5px);
             box-shadow: 0 8px 30px rgba(213, 63, 0, 0.4);
        }
    }

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
    }
    .sale-label { /* Стилі для надпису "Акція" */
        position: absolute;
        top: 10px;
        left: 10px;
        background-color: #D53F00;
        color: white;
        padding: 5px 10px;
        font-size: 14px;
        border-radius: 5px;
        font-weight: 600;
        z-index: 1;
    }

    .image {
        a {
            display: block;
            overflow: hidden;
            border-radius: 15px;
            position: relative;
            transition: transform 0.2s ease;
        }

        img {
            max-width: 100%;
            height: auto;
            object-fit: cover;
            transition: transform 0.3s ease;
        }

        &:hover img {
            transform: scale(1.1);
        }
    }

    .name {
        margin-top: 15px;
        a {
            text-decoration: none;
            color: #333;
            .name_cat {
                font-weight: 700;
                font-size: 20px;
                color: #333;
            }
        }
    }

    .brand {
        font-size: 14px;
        color: #888;
        margin-top: 5px;
        text-align: center;
    }

    .catalog_options {
        margin-top: 8px;
        font-size: 14px;
        p {
            margin: 0;
            font-weight: 600;
        }
        span {
            margin-top: 3px;
            color: #555;
        }
    }

    .price {
        margin-top: 15px;
        font-size: 22px;
        font-weight: bold;
        color: #D53F00;
        text-align: center;

        a {
            text-decoration: none;
            color: #D53F00;

            .price-new {
                color: #D53F00;
                font-size: 24px;
            }

            .price-old {
                color: #4C4E4A;
                margin-left: 10px;
                text-decoration: line-through;
            }
        }
    }

      .sale-countdown {
        margin-top: 5px;
        font-size: 14px;
        color: #4C4E4A;
        font-weight: 600;
    }

    .podrob {
        margin-top: 10px;
        a {
            text-decoration: none;
            color: #000;
            border-bottom: 1px solid transparent;
            transition: border-color 0.3s ease;

            &:hover {
                border-color: #D53F00;
                font-weight: bold;
            }
        }
    }

    .heart {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        transition: transform 0.3s ease, color 0.3s ease;

        &:hover {
            transform: scale(1.2);
            color: #D53F00;
        }
    }
}