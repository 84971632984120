@import  '../../variable.scss';

.account{
    background-color: $green;
    &__wrapper{
        display: flex;
    }

    &__menu{
        padding: 200px 20px 0;
    }

    &__link{
        display: block;
        width: 100%;
        @include simpleText();
        margin-bottom: 20px;
        cursor: pointer;
        text-decoration: none;
        border:none;
        outline: none;
        background-color: transparent;
        color: $white;
        text-align: left;
        padding: 0;
    }

    &__order-data, &__user-data{
        display: none;
    }

    &__menu{
        width: 20%;
        background-color: $gray;
    }

    &__data{
        width: 80%;
        padding: 100px 0 100px 20px;
    }

    &__title{
        color: $white;
    }

    hr{
        background-color: $black; 
        height: 0.5px; 
        border: 0;
        margin-top: 20px;
    }

    

    &__item {
        margin-top: 50px;
        width: 70%;
        position: relative;
        label{
            position: absolute;
            left: 5px;
            top: 3px;

            @include text(18px, 400, 33px);
        }

        &-flex{
            display: flex;
            justify-content: space-between;

            div{
                position: relative;
                width: 45%;
            }
        }

        &-image{
            display: flex;
            align-items: center;
            label{
                position: static;
            }
        }

        
    }

       

    &__input{
        width: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        border-bottom: 1px solid $gray;
        padding: 10px 5px;

        &:focus ~ label, &:not(:placeholder-shown) ~ label{
            top: -20px;
            @include text(14px, 400, 22px);
        } 
    }
}

@media screen and (max-width: 1000px){
    .account{
        &__menu{
            width: 30%;
        }

        &__item{
            width: 90%;
        }

        &__save{
            font-size: 18px;
            padding: 10px;
            width: 35%;
        }
    }
}

@media screen and (max-width: 768px){
    .account{
        &__save{
            width: 40%;
        }

        &__order-price{
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 678px){
    .account{
        &__menu{
            width: 40%;
        }

        &__link{
            font-size: 16px;
        }

        &__item{
            width: 100%;
            margin-top: 0;
            &-flex{
                display: block;

                div{
                    margin-top: 30px;
                    width: 100%;
                }
            }

            label{
                font-size: 16px;
            }
        }

        &__input{
            width: 70%;
        }

        &__save{
            width: 60%;
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 600px){
    .account{
        &__menu{
            width: 45%;
        }

        &__input{
            width: 90%;
        }
    }
}

@media screen and (max-width: 500px){
    .account{
        &__wrapper{
            display: block;
            padding-top: 10px;
        }

        &__menu{
            width: 100%;
            padding: 15px 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 10px
        }

        &__link{
            width: auto;
            margin-bottom: 0;
            text-transform: lowercase;
        }
        &__data{
            width: 100%;
            padding: 50px 0 100px 0;
        }
    }
}
