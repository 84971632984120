@import  '../../variable.scss';

.account{
    &__order{
        margin-top: 50px;

        &-item{
            padding-bottom: 50px;
            border-bottom: 1px solid $white;
            &+&{
                margin-top: 50px;
            }
        }

        &-product{
            display: flex;
            position: relative;
            
            & + &{
                margin-top: 25px;
            }
        }

        &-price{
            text-align: right;
            @include simpleText();

            span{
                font-weight: 500;
            }
        }

        &-info{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: 15px;
        }

        &-date{
            @include text(18px, 400, 28px);
            margin: 0;
        }

        &-status{
            @include simpleText();

            span{
                color: $red;
            }
        }

        &-send{
            @include button();
            width: 30%;
        }
    }
}